<script setup lang="ts">
import { useRoute } from "vue-router";

const config = useRuntimeConfig();
const route = useRoute();
</script>

<template>
    <div id="app-container">
        <div
            class="relative z-10 flex flex-col justify-between min-h-screen overflow-hidden transition-colors duration-150"
        >
            <SiteHeader/>

            <main class="flex-grow space-y-8 sm:space-y-16">
                <RouterView :key="route.path"/>
            </main>

            <SiteFooter/>
        </div>

    </div>
</template>

<style lang="scss">
    body * {
        outline: none;
    }
</style>